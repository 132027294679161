/***************************************************
This file holds all Sensitive and secret ENV variables.
Each ENV variable has the format all caps seperated by underscore 
and has to start with "REACT_APP_......"
****************************************************/

export const env = {
  apiURL: process.env.REACT_APP_API_URL,
  apiVersion: process.env.REACT_APP_API_VERSION,
};
