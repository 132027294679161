export const masterContact = {
  WHATSAPP: "whatsapp",
  FACEBOOK: "facebook",
  INSTAGRAM: "instagram",
  PHONE: "phone",
  TWITTER: "twitter",
  WEBSITE: "website",
  YOUTUBE: "youtube",
  LINKEDIN: "linkedin",
  EMAIL: "email",
};

export const masterReference = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  MANAGER: "manager",
  USER: "user",
};
