import axios from "axios";
import { StatusCodes } from "http-status-codes";

/**
 * Prevent the default behavior of form submissions and anchor elements with href="#".
 * This function adds event listeners to all forms and anchor elements and prevents their default actions.
 */
export const preventDefault = () => {
  try {
    document.querySelectorAll("form").forEach((form) => {
      form.addEventListener("submit", (e) => e.preventDefault());
    });
    document.querySelectorAll('a[href="#"]').forEach((a) => {
      a.addEventListener("click", (e) => e.preventDefault());
    });
  } catch (error) {
    console.error("Error in preventDefault:", error);
  }
};

//  Generate an array containing the lowercase alphabet letters from 'a' to 'z'.
export function generateAlphabet() {
  try {
    return [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
  } catch (error) {
    console.error("Error in generateAlphabet:", error);
    return [];
  }
}

/**
 * Format an API response or error into a consistent structure.
 */
export function formatApiResponse(responseOrError) {
  try {
    if (axios.isAxiosError(responseOrError)) {
      return {
        isOk: false,
        data: null,
        status:
          responseOrError.response && responseOrError.response.status
            ? responseOrError.response.status
            : StatusCodes.INTERNAL_SERVER_ERROR,
        error:
          responseOrError.response && responseOrError.response.data
            ? responseOrError.response.data.detail ||
              responseOrError.response.data
            : null,
      };
    }
    if (responseOrError instanceof Error) {
      return {
        isOk: false,
        data: null,
        status: StatusCodes.INTERNAL_SERVER_ERROR,
        error: null,
      };
    }
    return {
      isOk: true,
      ...responseOrError.data,
      status: responseOrError.status,
      error: null,
    };
  } catch (error) {
    console.error("Error in formatApiResponse:", error);
    return {
      isOk: false,
      data: null,
      status: StatusCodes.INTERNAL_SERVER_ERROR,
      error: "An error occurred while formatting the API response.",
    };
  }
}
