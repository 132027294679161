import axios from "axios";
import { env } from "../config/env";
import { getAuthToken } from "../contexts/authContext";

export const api = axios.create({
  baseURL: `${env.apiURL}/${env.apiVersion}`,
});

export const protectedApi = axios.create({
  baseURL: `${env.apiURL}/${env.apiVersion}`,
});

protectedApi.interceptors.request.use((request) => {
  const token = getAuthToken();
  if (!token) {
    throw new Error("Token not found in the storage");
  }
  request.headers["Authorization"] = `Bearer ${token}`;
  return request;
});

protectedApi.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);
