import { formatApiResponse } from "../utils/helpers";
import { api, protectedApi } from "../api";
import { getRefreshToken } from "../contexts/authContext";

// Constants for access and refresh token keys in local storage
const ACCESS_TOKEN_KEY = "accessToken";
const REFRESH_TOKEN_KEY = "refreshToken";

// Function to authenticate a user by email and password
export const loginApi = async (email, password) => {
  try {
    // Make a POST request to the login endpoint with email and password
    const res = await api.post("/login", { email, password });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to log out the user
export const logoutApi = async () => {
  // Get the refresh token from local storage
  const refreshToken = {
    refresh: getRefreshToken(),
  };

  try {
    // Make a POST request to the logout endpoint with the refresh token
    const res = await protectedApi.post("/logout", refreshToken);

    // Clear the user's session or token from the client-side storage.
    if (res && res["status"] === 204) {
      window.localStorage.removeItem(ACCESS_TOKEN_KEY);
      window.localStorage.removeItem(REFRESH_TOKEN_KEY);
    }

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to request a password reset
export const forgotPasswordAPI = async (email, company_url, language_code) => {
  let endpoint;

  if (company_url && company_url.length > 0) {
    endpoint = `${company_url}/forgot-password?language_code=${language_code}`;
  } else {
    endpoint = `/forgot-password`;
  }

  try {
    // Make a POST request to the forgot password endpoint with the email
    const res = await api.post(endpoint, { email });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get details of the logged-in user
export const getloggedInUserDetailApi = async () => {
  try {
    // Make a GET request to retrieve the user's profile
    const res = await protectedApi.get(`/users/profile`, {});

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to edit/update details of the logged-in user
export const editloggedInUserDetailApi = async (data) => {
  const endPoint = `/users/profile`;

  try {
    // Make a PUT request to update the user's profile with the provided data
    const res = await protectedApi.put(endPoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to change the user's password
export const editChangePasswordApi = async (data) => {
  const endpoint = `/change-password`;

  try {
    // Make a PUT request to change the user's password with the provided data
    const res = await protectedApi.put(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to Add the user's password
export const addPasswordApi = async (data) => {
  const endpoint = `/add-password`;

  try {
    // Make a POST request to change the user's password with the provided data
    const res = await protectedApi.post(endpoint, data);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to Disable add password
export const disableAddPasswordApi = async () => {
  const endpoint = `/add-password`;

  try {
    // Make a PUT request to Disable add password
    const res = await protectedApi.put(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to reset the user's password with a token
export const resetPasswordAPI = async (password, uidb64, token) => {
  const endpoint = "/set-password/";

  try {
    // Make a PATCH request to reset the user's password with the provided data
    const res = await api.put(endpoint, { password, uidb64, token });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to verify the user's password
export const verifyPasswordApi = async (data) => {
  const endpoint = `/verify-password/`;

  try {
    // Make a POST request to verify the user's password with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to send an OTP (One-Time Password) for verification
export const sendOtpApi = async (data) => {
  const endpoint = `/otp/send/`;

  try {
    // Make a POST request to send an OTP with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to verify an OTP (One-Time Password)
export const verifyOtpApi = async (data) => {
  const endpoint = `/otp/verify/`;
  try {
    // Make a POST request to verify an OTP with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    return formatApiResponse(e);
  }
};

// Function to resend an OTP (One-Time Password)
export const resendOtpApi = async (data) => {
  const endpoint = `/otp/resend/`;
  try {
    // Make a POST request to resend an OTP with the provided data
    const res = await protectedApi.post(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to update the user's email
export const updateEmailApi = async (data) => {
  const endpoint = `/users/update-email/`;
  try {
    // Make a PUT request to update the user's email with the provided data
    const res = await protectedApi.put(endpoint, data);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to check if an email already exists
export const checkEmailApi = async (email, entityType, entityId, companyId) => {
  const endpoint = `/check-email/?email=${email}&entity_type=${entityType}&entity_id=${entityId}&company_id=${companyId}`;
  try {
    // Make a GET request to validate the email
    const res = await api.get(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to check if an website already exists
export const checkWebsiteExistApi = async (website, entity_id) => {
  const endpoint = `/check-website/?website=${website}&entity_id=${entity_id}`;
  try {
    // Make a GET request to validate the website
    const res = await api.get(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to check if an company name already exists
export const checkCompanyNameExistApi = async (name, entity_id) => {
  const endpoint = `/check-company-name/?company_name=${name}&entity_id=${entity_id}`;
  try {
    // Make a GET request to validate the company name
    const res = await api.get(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to authenticate a user by email and password
export const SelfUserloginApi = async (companyUrl, email, password) => {
  try {
    // Make a POST request to the login endpoint with email and password
    const res = await api.post(`/${companyUrl}/login`, { email, password });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to get company status list
export const GetCompanyStatusList = async () => {
  try {
    // Make a GET request to get company status list
    const res = await api.get(`/companies/company-status-list`);

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// Function to request a contact us
export const contactUsAPI = async (fullname, email, message) => {
  let endpoint;

  endpoint = `/contact-us`;
  try {
    // Make a POST request to contact us endpoint with the email
    const res = await api.post(endpoint, { fullname, email, message });

    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const downloadMultipleEmployeeTimesheetAPI = async (
  companyUrl,
  start_date,
  end_date,
  company_id,
  language_code
) => {
  let endpoint;
  endpoint = `/${companyUrl}/time-sheet/download-timesheet?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}&language_code=${language_code}`;
  try {
    const res = await protectedApi.post(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const downloadDatevData = async (
  companyUrl,
  start_date,
  end_date,
  company_id,
  user_id = 0
) => {
  let endpoint;
  endpoint = `/${companyUrl}/time-sheet/download-datev?start_date=${start_date}&end_date=${end_date}&company_id=${company_id}&user_id=${user_id}`;
  try {
    const res = await protectedApi.get(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const getTOTPQR = async () => {
  let endpoint;
  endpoint = `/totp/create`;
  try {
    const res = await protectedApi.get(endpoint);
    // Format and return the API response
    return res;
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const verifyTOTPAPI = async (otp) => {
  let endpoint;
  endpoint = `/totp/login/${otp}`;
  try {
    const res = await protectedApi.post(endpoint);
    // Format and return the API response
    return res;
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const registerTOTPAPI = async (otp) => {
  let endpoint;
  endpoint = `/totp/create`;
  try {
    const res = await protectedApi.post(endpoint, { otp: otp });
    // Format and return the API response
    return res;
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const check2Fa = async (companyUrl, email, password) => {
  try {
    const res = await api.post(`/${companyUrl}/check-two-factor`, {
      email,
      password,
    });
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const verify2FaOtp = async (otp, email, companyUrl) => {
  try {
    const res = await api.post(`/${companyUrl}/verify-two-factor`, {
      otp,
      email,
    });
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const disable2FA = async (user_id) => {
  try {
    const res = await protectedApi.put(`/disable-two-factor/${user_id}`);
    // Format and return the API response
    return res;
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

// --------------Activate Account after Email Verification for New user

export const activateAccount = async (uidb64, token, type, language_code) => {
  try {
    const res = await api.get(
      `/users/user-activate/${uidb64}/${token}?type=${type}&language_code=${language_code}`
    );
    // Format and return the API response
    return res;
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};

export const verifyCaptcha = async (recaptcha_token) => {
  try {
    const res = await api.post(`/verify-captcha`, {
      recaptcha_token,
    });
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};