import React, { lazy } from "react";

import "./App.css";
import useAuth from "./hooks/useAuth";
import { SnackbarProvider } from "notistack";
import { masterReference } from "./constants/masterData";

// styles
import GlobalStyles from "./styles/global";


const AuthLayout = lazy(() => import("./layouts/AuthLayout"));
const AppLayout = lazy(() => import("./layouts/AppLayout"));
const SuperAdminLayout = lazy(() => import("./layouts/SuperAdminLayout"));

function App() {
  const { isAuthenticated, user } = useAuth();

  return (
    <div className="App">
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={3000}
      >
        <GlobalStyles />

        {isAuthenticated ? (
          // isAdminConsole &&
          (user.code === masterReference.SUPER_ADMIN ||
            user.code === masterReference.ADMIN) ? (
            <SuperAdminLayout />
          ) : (
            <AppLayout />
          )
        ) : (
          <AuthLayout />
        )}
        {/* </StyleSheetManager>  */}
        {/* </SidebarContextAPI> */}
      </SnackbarProvider>
    </div>
  );
}

export default App;
