/* eslint-disable no-unused-vars */
import { protectedApi, api } from "../api";
import { formatApiResponse } from "../utils/helpers";

// Get a list of users with optional filters.
export const getUsersList = async (requestParams) => {
  const {
    page,
    limit,
    company_id,
    role,
    sorting,
    status,
    searchText,
    companyUrl,
  } = requestParams;

  try {
    // Construct the query string for the API request.
    let param = `?limit=${limit}&page=${page}&company_id=${company_id}&role=${role}&sort=${sorting.sort}&order=${sorting.order}&status=${status}&q=${searchText}`;

    // Send a GET request to the 'users' endpoint with the constructed query string.
    const response = await protectedApi.get(`/${companyUrl}/users/` + param);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Handle any errors that occur during the API request and log them.
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get details of a specific user by its ID.
export const getUserDetail = async (requestParams, company_url) => {
  const { id } = requestParams;

  try {
    const response = await protectedApi.get(`/${company_url}/users/${id}`);

    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

// Update an existing teacher's details.
export const updateUsersDetail = async (requestParams, company_url) => {
  const { id, data, languageCode="en" } = requestParams;

  try {
    // Send a PUT request to the 'users' endpoint with the provided data.
    const response = await protectedApi.put(
      `/${company_url}/users/${id}?language_code=${languageCode}`,
      data
    );

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get the user's profile.
export async function getProfile() {
  try {
    // Send a GET request to the 'users/profile' endpoint to retrieve the user's profile.
    const res = await protectedApi.get(`/users/profile`);

    // Format and return the API response.
    return formatApiResponse(res);
  } catch (e) {
    // Format and return an error response.
    return formatApiResponse(e);
  }
}

// Update user profile
export const updateProfile = async (requestParams) => {
  const { data } = requestParams;

  try {
    // Send a PUT request to the 'users' endpoint with the provided data.
    const response = await protectedApi.put(`/users/profile`, data);

    // Format and return the API response.
    return formatApiResponse(response);
  } catch (e) {
    // Format and return an error response.
    return formatApiResponse(e);
  }
};

// Get the country list.
export async function getCountryList() {
  try {
    // Send a GET request Country List.
    const res = await api.get("/countries");

    // Format and return the API response.
    return formatApiResponse(res);
  } catch (e) {
    // Format and return an error response.
    return formatApiResponse(e);
  }
}

export const getCompaniesListForUser = async () => {
  try {
    const response = await protectedApi.get("/companies/list");
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const getUserRolesListApi = async () => {
  try {
    const response = await protectedApi.get("/roles");
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const addUserApi = async (requestParams) => {
  const { data, companyUrl, languageCode="en" } = requestParams;

  const endpoint = `/${companyUrl}/users/?language_code=${languageCode}`;
  try {
    const response = await protectedApi.post(endpoint, data);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export const getSelfUserApi = async (companyUrl, guid) => {
  const endpoint = `${companyUrl}/register/${guid}`;
  try {
    const response = await api.get(endpoint);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export const addSelfUserApi = async (
  companyUrl,
  userData,
  guid,
  languageCode
) => {
  const endpoint = `${companyUrl}/register/${guid}?language_code=${languageCode}`;
  try {
    const response = await api.post(endpoint, { ...userData });
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

export const approveUser = async (userId, company_url) => {
  try {
    const response = await protectedApi.post(
      `/${company_url}/users/${userId}/approve/`,
      userId
    );
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const unApproveUser = async (userId, company_url) => {
  try {
    const response = await protectedApi.post(
      `/${company_url}/users/${userId}/unapprove/`,
      userId
    );
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const enableUser = async (userId, company_url, languageCode) => {
  try {
    const response = await protectedApi.post(
      `/${company_url}/users/${userId}/enable/?language_code=${languageCode}`,
      userId
    );
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const disableUser = async (userId, company_url) => {
  try {
    const response = await protectedApi.post(
      `/${company_url}/users/${userId}/disable/`,
      userId
    );
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const getUserManagerListApi = async (userId, companyId, company_url) => {
  try {
    let response;

    if (companyId === 0) {
      response = await protectedApi.get(
        `/${company_url}/users/list?user_id=${userId}&company_id=1`
      );
    } else {
      response = await protectedApi.get(
        `/${company_url}/users/list?user_id=${userId}&company_id=${companyId}`
      );
    }

    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const getUserResourceListApi = async (requestParams, company_url) => {
  const { page, limit, company_id, role, sorting, status, searchText } =
    requestParams;

  try {
    // Construct the query string for the API request.
    let param = `?limit=${limit}&page=${page}`;

    const response = await protectedApi.get(
      `/${company_url}/users/manager` + param
    );
    return formatApiResponse(response);
  } catch (e) {
    return formatApiResponse(e);
  }
};

export const getLoginForCompany = async (companyUrl) => {
  const endpoint = `/companies/${companyUrl}`;
  try {
    const response = await api.get(endpoint);
    return formatApiResponse(response);
  } catch (error) {
    return formatApiResponse(error);
  }
};

// Function to check if an employee id already exists
export const checkEmployeeIdApi = async (company_url, employee_id, user_id) => {
  const endpoint = `${company_url}/users/validate/employee-id?employee_id=${employee_id}&user_id=${user_id}`;
  try {
    // Make a GET request to validate the email
    const res = await protectedApi.get(endpoint);
    // Format and return the API response
    return formatApiResponse(res);
  } catch (e) {
    // Format and return the error response
    return formatApiResponse(e);
  }
};
